import React from 'react'
import FAQs from './FAQs'

const FAQ = () => {
  return (
    <>
      <FAQs />
    </>
  )
}

export default FAQ
