const config = [
  {
    title: {
      id: 999,
      fallback: 'What is CardioSwap DEX?',
    },
    description: [
      {
        id: 999,
        fallback: 'CardioSwap DEX is a decentralized exchange (DEX) built on the Ethereum blockchain. It allows users to trade digital assets in a secure, transparent, and trustless manner.',
      },
    ],
  },
  {
    title: {
      id: 999,
      fallback: 'How do I start using CardioSwap DEX?',
    },
    description: [
      {
        id: 999,
        fallback: 'To start using CardioSwap DEX, you need to have a wallet that supports Ethereum. Connect your wallet to the DEX and start trading by accessing the CardioSwap website or through compatible wallet applications.',
      },
    ],
  },
  {
    title: { id: 999, fallback: 'What tokens can I trade on CardioSwap DEX?' },
    description: [
      {
        id: 999,
        fallback: 'CardioSwap DEX supports a variety of tokens, including Ethereum-compatible assets. The available tokens will depend on the liquidity provided by users and the listing decisions of the CardioSwap team.',
      },
    ],
  },
  {
    title: { id: 999, fallback: 'How secure is CardioSwap DEX?' },
    description: [
      {
        id: 999,
        fallback: 'CardioSwap DEX leverages the security and immutability of the Ethereum blockchain, providing a high level of security for your trades. However, it is important to exercise caution and follow best practices for securing your wallet and private keys.',
      },
    ],
  },
  {
    title: { id: 999, fallback: 'Are there any fees associated with using CardioSwap DEX?' },
    description: [
      {
        id: 999,
        fallback: 'Yes, CardioSwap DEX charges fees on transactions to incentivize liquidity providers and cover the costs of maintaining the platform. The fee structure and rates will be transparently displayed on the DEX interface.',
      },
    ],
  },
  {
    title: { id: 999, fallback: 'How can I provide liquidity to CardioSwap DEX?' },
    description: [
      {
        id: 999,
        fallback: 'You can become a liquidity provider on CardioSwap DEX by adding your tokens to the liquidity pools. By doing so, you can earn a portion of the trading fees generated by the DEX.',
      },
    ],
  },
  {
    title: { id: 999, fallback: 'How can I contact the CardioSwap support team?' },
    description: [
      {
        id: 999,
        fallback: 'For any inquiries or support requests, you can reach out to the CardioSwap team through their official channels, such as their website, Telegram group, or social media accounts. They will be happy to assist you.',
      },
    ],
  },
];

export default config;
